/* eslint-disable */

import mitt from 'mitt';

const emitter = new mitt();

export const xcore = {
  isDebug: false,
  isReady: false,
  savedEvents: [],
  trigger(name, params) {
    name = name.toLowerCase();
    if (this.isReady) {
      if (this.isDebug && typeof window.console !== 'undefined') {
        if (params) {
          console.log(`xcore: fire '${name}' event with arguments`, params);
        } else {
          console.log(`xcore: fire '${name}' event`);
        }
      }
      emitter.emit(name, params);
    } else {
      this.savedEvents.push({
        name,
        params,
      });
    }
  },
  bind(name, callback) {
    name = name.toLowerCase();
    if (this.isDebug && typeof window.console !== 'undefined') {
      console.log(`xcore: bind callback to '${name}' event`, callback);
    }
    emitter.on(name, (params) => {
      callback({}, params);
    });
    return this;
  },
  init() {
    window.addEventListener('load', () => {
      window.xcore.isReady = true;
      window.xcore.trigger('load');
      for (let i = 0; i < window.xcore.savedEvents.length; i++) {
        window.xcore.trigger(window.xcore.savedEvents[i].name, window.xcore.savedEvents[i].params);
      }
      window.xcore.savedEvents = [];
    });
  },
};
